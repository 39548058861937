import dynamic from 'next/dynamic'
import React, { useState } from 'react'

import { BlockLogo, ImgLogo, WrapChart } from '../style'

const ReactApexChart = dynamic(() => import('react-apexcharts'), { ssr: false })

const ChartContainer = () => {
  const [chartData] = useState({
    series: [80, 2, 3, 5, 10],
    options: {
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: true,
        minAngleToShowLabel: 1,
        formatter(val, opt) {
          return `${opt.w.globals.labels[opt.seriesIndex]}: ${val}%`
        },
        background: {
          enabled: true,
          foreColor: '#FFF',
          borderRadius: 2,
          padding: 2,
          borderWidth: 0,
          borderColor: '#000',
        },
        fill: '#000',
        textAnchor: 'start',
        dropShadow: {
          enabled: false,
        },
        style: {
          fontSize: '10px',
          fontFamily: 'SUISSE_BOLD',
          colors: ['#000', '#000'],
        },
      },
      chart: {
        foreColor: '#ffffff',
        fontFamily: 'SUISSE_BOLD',
      },
      legend: {
        show: false,
      },
      labels: ['Mint', 'Team', 'Airdrop', 'DEX', 'CEX'],
      stroke: {
        width: 2,
      },
      responsive: [
        {
          breakpoint: 767,
          options: {
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '8px',
                fontFamily: 'SUISSE_BOLD',
              },
            },
            chart: {
              width: 320,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      fill: {
        colors: ['rgba(262,198,70)', 'rgba(242,200,110)', 'rgba(244,183,24)'],
      },
      plotOptions: {
        pie: {
          dataLabels: {
            minAngleToShowLabel: 0,
          },
        },
      },
    },
  }) as any

  return (
    <WrapChart>
      <ReactApexChart options={chartData.options} series={chartData.series} type="pie" width={425} />
      <BlockLogo>
        <ImgLogo src="/logo.png" />
      </BlockLogo>
    </WrapChart>
  )
}

export default ChartContainer
