import { Button } from '@pancakeswap/uikit/src/components/Button'
import {
  BlockInfoToken,
  ButtonLink,
  DescInfo,
  ImgLink,
  LinkItem,
  ListListLink,
  TextLink,
  TitleInfo,
  WrapDirect,
} from './style'

const InfoToken = () => {
  return (
    <BlockInfoToken>
      <TitleInfo>BNB-20</TitleInfo>
      <TitleInfo>A new token standard for the Binance network</TitleInfo>
      <DescInfo>
        BNB20 is a new token standard created on the Binance network. This standard is designed to provide flexibility,
        security, and scalability for blockchain developers.
      </DescInfo>
      <DescInfo>
        BNB20 is based on the Ethereum ERC-20 standard but includes several significant enhancements. Firstly, BNB20
        uses the Inscription mechanism to store token data on the Binance blockchain. This mechanism enhances the
        security and scalability of the token. Secondly, BNB20 supports advanced functions such as staking, governance,
        and NFTs.
      </DescInfo>
      <DescInfo>
        BNB20 has the potential to become a popular token standard on the Binance network. This standard offers
        developers a flexible and secure platform for creating new blockchain applications
      </DescInfo>
      <WrapDirect>
        <ButtonLink href="/mint-token">
          <Button>Start Mint BNB-20</Button>
        </ButtonLink>
        <ListListLink>
          <LinkItem href="/" target="_blank">
            <ImgLink src="/images/icWhitePaper.svg" />
            <TextLink>White Paper</TextLink>
          </LinkItem>
          <LinkItem href="https://bscscan.com/address/0xe45BF86C862B502494F3A6f2B1e2400Bc9B54591#code" target="_blank" rel="noopener">
            <ImgLink src="/images/icContract.svg" />
            <TextLink>Contract</TextLink>
          </LinkItem>
          <LinkItem href="https://twitter.com/bnb20chain" target="_blank" rel="noopener">
            <ImgLink src="/images/icX.svg" />
            <TextLink>Twitter</TextLink>
          </LinkItem>
        </ListListLink>
      </WrapDirect>
    </BlockInfoToken>
  )
}
export default InfoToken
