import React from 'react'
import styled from 'styled-components'

const OuterCircle = styled.div`
  background: #ff4e50;
  background: -webkit-linear-gradient(to right, #f9d423, #ff4e50);
  background: linear-gradient(to right, #f9d423, #ff4e50);
  width: 375px;
  height: 375px;
  border-radius: 50%;
  animation: rotateCircle 2s linear infinite;
  padding: 5px;
  position: absolute;
  @media screen and (max-width: 767px) {
    width: 282.5px;
    height: 282.5px;
  }
`

const InnerCircle = styled.div`
  background: #000;
  height: 372.5px;
  width: 372.5px;
  border-radius: 50%;
  animation: rotateCircle 2s linear infinite reverse;
  @media screen and (max-width: 767px) {
    width: 280px;
    height: 280px;
  }
`

const CircleSVG = () => {
  return (
    <OuterCircle>
      <InnerCircle />
    </OuterCircle>
  )
}

export default CircleSVG
