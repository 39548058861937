import styled from "styled-components";
import Image from "next/image";
import { Button } from "@pancakeswap/uikit";

export const BlockDashboard  = styled.div`
    padding : 30px 0px;
    @media screen and (max-width : 1199px) {
        padding : 30px 0px 150px 0px;
    }
`;
export const BlockDashboardWrap  = styled.div`
    position : relative;
    z-index : 2;
`;

export const BlockBanner = styled.div`
    @media screen and (max-width : 1199px) {
        max-width : 100%;
    }
    width : 100%;
    max-width : 1000px;
    height : 250px;
    background: ${({ theme }) => theme.colors.gradientPrimary};
    border-radius : 24px;
    padding: 24px;
    margin : 0 auto;
    position : relative;
    display : flex;
    align-items : center;
    justify-content : center;
`

export const ImageBanner = styled(Image)`
    width: 180px;
    height: auto;
    object-fit : contain;
    position : absolute;
    right: 50px;
    bottom : 0;
    user-select : none;
    pointer-events : none;
`

export const BannerContent = styled.div`
   
`
export const TitleBanner = styled.div``
export const ContentTrade = styled.div`
    position : absolute;
    width:  280px;
    bottom : 30px;
    left : -30px;
    background : ${({ theme }) => theme.colors.borderBlur};
    backdrop-filter : blur(20px);
    padding: 12.5px 10px;
    border-radius : 14px;
    overflow : hidden;
    display : flex;
    align-items : flex-start;
    border : 1px solid rgba(255,255,255,0.25);
    @media screen and (max-width : 1199px) {
       display : none;
    }
`
export const ContentSwap = styled.div`
    position : absolute;
    width:  280px;
    top : 30px;
    right  : -40px;
    background : ${({ theme }) => theme.colors.borderBlur};
    backdrop-filter : blur(20px);
    padding: 12.5px 10px;
    border-radius : 14px;
    overflow : hidden;
    display : flex;
    align-items : flex-start;
    border : 1px solid rgba(255,255,255,0.25);
    @media screen and (max-width : 1199px) {
        display : none;
    }
`
export const BlockImage = styled.div`
    width:  60px;
    height : 60px;
    background : ${({ theme }) => theme.colors.background};
    border-radius : 10px;
    margin : 0px 12.5px 0px 0px;
`
export const ImageTrade = styled(Image)`
    width: 100%;
    height: 100%;
    user-select : none;
    pointer-events : none;
`
export const BlockContent = styled.div`
    
`;
export const TextTitle = styled.div`
    color : rgba(255,255,255,0.85);
    font-family : SUISSE_BLACK;
    font-size : 1.4rem;
    line-height: calc(1.4rem * 1.25);
`;
export const BlockListAvatar = styled.div`
    margin : 12.5px 0px 0px 5px;
`;
export const ItemAvatar = styled(Image)`
    border-radius : 50%;
    width : 22px;
    height: 22px;
    margin : 0px 0px 0px -5px;
    border : 2px solid #FFF;
    user-select : none;
    pointer-events : none;
`;
export const TextTime = styled.div`
    color : rgba(255,255,255,0.65);
    font-size : 1.25rem;
    line-height: calc(1.25rem * 1.25);
    position : absolute;
    right: 15px;
    bottom : 15px;
`;

export const BlockInfo = styled.div`
    display : flex;
    flex-direction : column;
    align-items : center;
    justify-content : center;
`;
export const TitleInfo = styled.div`
    font-family : SUISSE_BLACK;
    font-size : 2.8rem;
    line-height: calc(2.8rem * 1.25);
    color : #FFF;
    display : flex;
    align-items : center;
    justify-content : center;
    margin : 0px 0px 0px calc(- 150px + 75px);
    @media screen and (max-width : 1199px) {
        font-size : 2rem;
        line-height: calc(2rem * 1.25);
    }
`;
export const WrapTextChange = styled.div`
    width: 150px;
    height : 50px;
    position : relative;
    background : ${({ theme }) => theme.colors.background};
    margin : 0px 10px 0px 0px;
    border-radius : 25% / 50px;
    border-top-left-radius : 20px;
    border-bottom-left-radius : 20px;
    display : flex;
    align-items : center;
    justify-content : center;
    @media screen and (max-width : 1199px) {
       width : 120px;
       height: 40px;
    }
`;
export const TextChange = styled.div`
    position: absolute;
    font-family : SUISSE_BLACK;
    text-align : center;
    right:  0px;
    left : 0px;
    background: ${({ theme }) => theme.colors.gradientPrimary};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`
export const TextInfo = styled.div`
    font-family : SUISSE_BLACK;
`
export const TitleDesc = styled.div`
    font-family : SUISSE_BOLD;
    margin : 20px 0px 0px 0px;
    color : rgba(255,255,255,0.75);
    font-size : 1.8rem;
    line-height: calc(1.8rem * 1.25);
    text-align : center;
    @media screen and (max-width : 1199px) {
        font-size : 1.4rem;
        line-height: calc(1.4rem * 1.25);
    }
`
export const BlockButton = styled(Button)`
    margin : auto;
    box-shadow : none;
    background : ${({ theme }) => theme.colors.backgroundReverse};
    color : ${({ theme }) => theme.colors.colorReverse};
    margin : 30px 0px 0px 0px;
    border-radius : 10px;
`

// FEATURES
export const BlockFeatures = styled.div`
    margin : 50px 0px 0px 0px;
    max-width : 1000px;
    width: 100%;
    margin : 50px auto;
    display : flex;
    align-items : stretch;
    flex-wrap : wrap;
`;
export const FeaturesItem = styled.div`
    width:  calc(100% /  2 - (20px - 20px / 2));
    background : ${({ theme }) => theme.colors.backgroundElement};
    padding:  24px 30px;
    border-radius : 24px;
    margin : 0px 20px 20px 0px;
    display : flex;
    flex-direction : column;
    align-items : center;
    justify-content : flex-start;
    &:nth-child(2n) {
        margin : 0px 0px 20px 0px;
    }
    svg {
        width : 35px;
        height: 35px;
    }
    @media screen and (max-width : 767px) {
        width : 100%;
        margin : 0px 0px 20px 0px;
    }
`;
export const TitleFeatures = styled.div`
    margin : 20px 0px 0px 0px;
    text-align : center;
    font-family : SUISSE_BOLD;
    font-size : 2.4rem;
    line-height:  calc(2.4rem * 1.25);
    padding: 0px 25px;
    color : ${({ theme }) => theme.colors.color};
`;
export const ContentFeatures = styled.div`
    text-align : center;
    color : #A5A5A5;
    font-family : SUISSE_MEDIUM;
    margin : 7.5px 0px 0px 0px;
`;
export const ImageFeatures = styled(Image)`
    width: 100%;
    height : 300px;
    object-fit : cover;
    margin : 30px 0px 0px 0px;
    border-radius : 20px;
    @media screen and (max-width : 767px) {
       width : 100%;
       max-width: 400px;
       height: 260px;
    }
`;


export const WrapInfo = styled.div`
    display : flex;
    align-items : center;
    justify-content : space-between;
    @media screen and (max-width : 1199px) {
       flex-direction : column;
    }
`
export const WrapMintToken = styled.div`
    padding: 0px 0px 75px 0px;
`

export const VideoDashboardBackground = styled.video`
    position : fixed;
    top : 0;
    left : 0;
    object-fit : cover;
    width: 100vw;
    height: 100vh;
`