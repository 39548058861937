import Link from "next/link";
import styled from "styled-components";

export const BlockTokenomics = styled.div`
  width : calc(50% - 20px);
  padding: 50px 125px 50px 0px;
  @media screen and (max-width : 1199px) {
    width : 100%;
    padding: 0px;
    margin : 50px 0px 0px 0px;
  }
`

export const ContentWrap = styled.div``
export const WrapChartTokenomics = styled.div`
  display : flex;
  flex-direction : column;
  align-items: center;
  justify-content : center; 
  @media screen and (max-width : 1199px) {
    flex-direction : column;
    margin : 40px 0px 0px 0px;
  }
`

export const InfoTokenomics = styled.div`
  width: 100%;
  max-width : 400px;
  border: 2px solid rgb(255,179,71);
  border-radius : 24px;
  margin : 0px 0px 0px 130px;
  padding: 10px 20px 25px 20px;
`

export const ItemTokenomics = styled.div`
  font-family : F_BOLD;
  margin : 15px 0px 0px 0px;
  font-size : 1.6rem;
  display : flex;
  flex-direction : column;
  line-height: calc(1.6rem * 1.25);
  word-break : break-word;
  @media screen and (max-width : 1199px) {
    font-size : 1.25rem;
    line-height: calc(1.25rem * 1.25);
  }
`

export const WrapCircle = styled.div`
    display : flex;
    align-items : center;
    justify-content : center;
    margin : 0px 0px 0px 0px;
    position : relative;
    svg {
      position : absolute;
      filter : blur(20px)
      z-index : 0;
    }
`

export const WrapChart = styled.div`
  display : flex;
  position : relative;
  z-index : 1;
  align-items : center;
  justify-content : center;
`

export const BlockLogo = styled.div`
  position : absolute;
  width: 100px;
  height : 100px;
  display : flex;
  background : #000;
  align-items : center;
  justify-content : center;
  border-radius : 50%;
  border : 3px solid #FFF;
`

export const ImgLogo = styled.img`
  width: 65px;
  height : 65px;
  object-fit : contain;
`

export const WrapLinkDirect = styled.div`
  display : flex;
  margin : 15px 0px 0px 0px;
`

export const LinkDirect = styled(Link)`
  height: 40px;
  width : 50%;
  margin : 0px 5px;
  cursor : pointer;
  background : rgb(255,179,71);
  border-radius : 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display : flex;
  align-items : center;
  justify-content : center;
  color : #000;
  text-align : center;
  transition : 0.4s all;
  &:hover {
    filter : brightness(0.8);
    transition : 0.4s all;
  }
`

export const WrapTitle = styled.div`
  margin : 0px 0px 50px 0px;
`

export const TextTitle = styled.div`
  font-family : SUISSE_BOLD;
  font-size : 2.4rem;
  line-height : calc(2.4rem * 1.25);
  @media screen and (max-width : 1199px) {
    font-size : 1.8rem;
    line-height : calc(1.8rem * 1.25);
  }
`