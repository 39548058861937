import Dashboard from 'views/Dashboard'

const IndexPage = () => {
  return (
    <Dashboard />
  )
}

IndexPage.chains = []

export default IndexPage
