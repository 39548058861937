import ParticleAnimation from 'react-particle-animation'
import InfoToken from './components/InfoToken'
import Token from './components/MintToken'
import Tokenomics from './components/Tokenomics'

import { BlockDashboard, BlockDashboardWrap, VideoDashboardBackground, WrapInfo, WrapMintToken } from './style'

const Dashboard = () => {
  return (
    <BlockDashboard>
      <VideoDashboardBackground src="/bgDashboard.mp4" autoPlay loop muted />
      <BlockDashboardWrap className="blockContainer">
        <WrapInfo>
          <InfoToken />
          <Tokenomics />
        </WrapInfo>
      </BlockDashboardWrap>
    </BlockDashboard>
  )
}

export default Dashboard
