import React, { useEffect, useState } from 'react'
import 'react-fancy-circular-carousel/FancyCarousel.css'

// import { ChainId } from '@pancakeswap/swap-sdk'
import dynamic from 'next/dynamic'
import { useChainId } from 'wagmi'

// import { BTCETF_SPOT } from '@/config/constants/tokens'

import ChartContainer from './ChartContainer'
import {
  BlockTokenomics,
  ContentWrap,
  InfoTokenomics,
  ItemTokenomics,
  LinkDirect,
  TextTitle,
  WrapChartTokenomics,
  WrapCircle,
  WrapLinkDirect,
  WrapTitle,
} from './style'
import CircleSVG from './CircleSVG'

const FancyCarousel = dynamic(() => import('react-fancy-circular-carousel'), { ssr: false })

const imagesToken = [
  '/images/tokenomics/eth.png',
  '/images/tokenomics/usdt.png',
  '/images/tokenomics/bnb.png',
  '/images/tokenomics/xrp.png',
  '/images/tokenomics/sol.png',
  '/images/tokenomics/usdc.png',
  '/images/tokenomics/ada.png',
  '/images/tokenomics/avax.png',
  '/images/tokenomics/doge.png',
  '/images/tokenomics/trx.png',
  '/images/tokenomics/zkSync.png',
  '/images/tokenomics/arb.png',
  '/images/tokenomics/op.png',
]

const Tokenomics = () => {
  const [isMobile, setIsMobile] = useState(false)

  const chainId = useChainId()
  // const token = BTCETF_SPOT[chainId] || BTCETF_SPOT[ChainId.BSC]

  useEffect(() => {
    const checkWindowSize = () => {
      setIsMobile(window.innerWidth < 767)
    }
    checkWindowSize()
    window.addEventListener('resize', checkWindowSize)
    return () => {
      window.removeEventListener('resize', checkWindowSize)
    }
  }, [])
  return (
    <BlockTokenomics id="tokenomics">
      <ContentWrap className="blockContainer">
        <WrapChartTokenomics>
          <WrapTitle>
            <TextTitle>Tokenomics</TextTitle>
          </WrapTitle>
          <WrapCircle>
            <ChartContainer />
            <CircleSVG />
          </WrapCircle>
          {/* <InfoTokenomics>
            <ItemTokenomics>Total Supply: 21.000.000</ItemTokenomics>
            <br />
            <ItemTokenomics>Burn : 2.310.000 (11%)</ItemTokenomics>
            <ItemTokenomics>Presale : 7.350.000 (35%)</ItemTokenomics>
            <ItemTokenomics>Mint Airdrop : 5.250.000 (25%)</ItemTokenomics>
            <ItemTokenomics>CEX : 2.100.000 (10%)</ItemTokenomics>
            <ItemTokenomics>Team : 1.050.000 (5%)</ItemTokenomics>
            <ItemTokenomics>Lab : 2.940.000 (14%)</ItemTokenomics>
            <br />
            <ItemTokenomics>
              Token Address
              <WrapLinkDirect>
                <LinkDirect href={`https://bscscan.com/address/${token?.address}`} target="_blank">
                  BscScan
                </LinkDirect>
                <LinkDirect href={`https://optimistic.etherscan.io/address/${token?.address}`} target="_blank">
                  EtherScan
                </LinkDirect>
              </WrapLinkDirect>
            </ItemTokenomics>
          </InfoTokenomics> */}
        </WrapChartTokenomics>
      </ContentWrap>
    </BlockTokenomics>
  )
}
export default Tokenomics
