import Link from "next/link";
import styled from "styled-components";

export const BlockInfoToken = styled.div`
    width: calc(50% - 20px);
    @media screen and (max-width : 1199px) {
        width : 100%;
    }
`
export const TitleInfo = styled.div`
    font-size : 3.6rem;
    line-height: calc(3.6rem * 1.25);
    font-family : SUISSE_BLACK;
    @media screen and (max-width : 1199px) {
       text-align : center;
       font-size : 2.2rem;
       line-height: calc(2.2rem * 1.25);
    }
`
export const DescInfo = styled.div`
    font-size : 1.6rem;
    line-height: calc(1.6rem * 1.25);
    font-family : SUISSE_MEDIUM;
    margin : 20px 0px 0px 0px;
    @media screen and (max-width : 1199px) {
        text-align : center;
        font-size : 1.25rem;
        line-height: calc(1.25rem * 1.25);
        margin : 10px 0px 0px 0px;
    }
`

export const ButtonLink = styled(Link)`
@media screen and (max-width : 1199px) {
   width: 100%;
   display : flex;
 }
    button {
        width : fit-content;
        padding : 0px 25px;
        height: 50px;
        font-family : SUISSE_BOLD;
        color : #000;
        @media screen and (max-width : 1199px) {
            margin : 0px auto 0px auto;
            height: 40px;
            font-size : 1.25rem;
            line-height: calc(1.25rem * 1.25);
            border-radius : 5px;
        }
    }
`

export const WrapDirect = styled.div`
    display : flex;
    flex-direction : column;
    margin : 25px 0px 0px 0px;
    @media screen and (max-width : 1199px) {
        margin : 15px 0px 0px 0px;
    }
`
export const ListListLink = styled.div`
    display : flex;
    margin : 20px 0px 0px 0px;
    @media screen and (max-width : 1199px) {
        justify-content : center;
        margin : 15px 0px 0px 0px;
    }
`
export const LinkItem = styled(Link)`
    margin : 0px 12.5px 0px 0px;
    width: fit-content;
    background : #000;
    border : 1.5px solid rgba(241,171,91);
    padding: 10px 15px 10px 10px;
    border-radius : 8px;
    transition : 0.4s all;
    cursor : pointer;
    display : flex;
    &:hover {
        filter : brightness(0.7);
        transition : 0.4s all;
    }
`
export const ImgLink = styled.img`
    width : 20px;
    height: 20px;
    filter : invert(1);
    margin : 0px 10px 0px 0px;
    @media screen and (max-width : 1199px) {
        width : 16px;
        height: 16px;
    }
    @media screen and (max-width : 380px) {
        width : 12px;
        height: 12px;
    }
`

export const TextLink = styled.div`
    color : #FFF;
    font-family : SUISSE_BOLD;
    @media screen and (max-width : 1199px) {
        font-size : 1.25rem;
        line-height: calc(1.25rem * 1.25);
    }
    @media screen and (max-width : 380px) {
        font-size : 1rem;
        line-height: calc(1rem * 1.25);
    }
`